import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const mitPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Cookie Policy" />
      <div className="container container-max-md">
        <article className="entry">
          <h1 className="h4">Cookie Policy</h1>
          <p className="text-muted"><small>Updated June, 30 2021</small></p>
          <p>Cookies</p>
          <p>
            Care was taken not to use 3rd party cookies on this website. However, in order to collect statistical data about visitors, your session information, especially Google, can be shared with various companies. 1st party cookies will be used for site performance and experience, and all this data will not be used for any advertising or targeting. <b>By visiting this website, you agree to this policy.</b>
          </p>
        </article>
      </div>
    </Layout>
  )
}

export default mitPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
